// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-form_container__O4u3- {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 2rem 0;
}

.contact-form_formWrapper__GDeCx {
  max-width: 64rem;
  width: 100%;
  background: linear-gradient(to bottom, rgba(31, 41, 55, 0.5), rgba(31, 41, 55, 0.3));
  backdrop-filter: blur(1rem);
  border-radius: 0.75rem;
  padding: 1.25rem;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(55, 65, 81, 0.5);
}

.contact-form_loadingContainer__Ak8kO {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.contact-form_content__dgZ2g {
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

.contact-form_leftColumn__IJ2VP {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.contact-form_infoSection__N68io {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form_rightColumn__P3\\+Fq {
  display: none;
}

@media (min-width: 1024px) {
  .contact-form_content__dgZ2g {
    grid-template-columns: repeat(2, 1fr);
  }

  .contact-form_leftColumn__IJ2VP {
    align-items: flex-start;
  }

  .contact-form_rightColumn__P3\\+Fq {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/contactStyles/contact-form.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,oFAAoF;EACpF,2BAA2B;EAC3B,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,0BAA0B;EAC1B,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,qCAAqC;EACvC;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;AACF","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 1rem;\n  margin: 2rem 0;\n}\n\n.formWrapper {\n  max-width: 64rem;\n  width: 100%;\n  background: linear-gradient(to bottom, rgba(31, 41, 55, 0.5), rgba(31, 41, 55, 0.3));\n  backdrop-filter: blur(1rem);\n  border-radius: 0.75rem;\n  padding: 1.25rem;\n  position: relative;\n  overflow: hidden;\n  border: 1px solid rgba(55, 65, 81, 0.5);\n}\n\n.loadingContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 200px;\n}\n\n.content {\n  position: relative;\n  z-index: 10;\n  display: grid;\n  grid-template-columns: 1fr;\n  gap: 1.5rem;\n}\n\n.leftColumn {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 1.5rem;\n}\n\n.infoSection {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.rightColumn {\n  display: none;\n}\n\n@media (min-width: 1024px) {\n  .content {\n    grid-template-columns: repeat(2, 1fr);\n  }\n\n  .leftColumn {\n    align-items: flex-start;\n  }\n\n  .rightColumn {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `contact-form_container__O4u3-`,
	"formWrapper": `contact-form_formWrapper__GDeCx`,
	"loadingContainer": `contact-form_loadingContainer__Ak8kO`,
	"content": `contact-form_content__dgZ2g`,
	"leftColumn": `contact-form_leftColumn__IJ2VP`,
	"infoSection": `contact-form_infoSection__N68io`,
	"rightColumn": `contact-form_rightColumn__P3+Fq`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactButtons_buttonGrid__LxdpQ {
  display: grid;
  gap: 1rem;
}

.ContactButtons_button__akzaS {
  position: relative;
  overflow: hidden;
  border-radius: 0.75rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.ContactButtons_button__akzaS:hover {
  --tw-scale-x: 1.02;
  --tw-scale-y: 1.02;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.ContactButtons_button__akzaS {
}

.ContactButtons_buttonGradient__gjr5u {
  position: absolute;
  inset: 0px;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #1f2937 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #374151 var(--tw-gradient-to-position);
  opacity: 0.95;
}

.ContactButtons_buttonContent__accKX {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
}

.ContactButtons_iconContainer__aI5uo {
  border-radius: 0.5rem;
  padding: 0.75rem;
}

.ContactButtons_sendIcon__PfmKG {
  display: flex;
  height: 2rem;
  width: 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
}`, "",{"version":3,"sources":["webpack://./src/styles/contactStyles/ContactButtons.module.css"],"names":[],"mappings":"AACE;EAAA,aAAiB;EAAjB;AAAiB;;AAIjB;EAAA,kBAAyF;EAAzF,gBAAyF;EAAzF,sBAAyF;EAAzF,wBAAyF;EAAzF,wDAAyF;EAAzF;AAAyF;;AAAzF;EAAA,kBAAyF;EAAzF,kBAAyF;EAAzF;AAAyF;;AAD3F;AAGA;;AAGE;EAAA,kBAA6E;EAA7E,UAA6E;EAA7E,qEAA6E;EAA7E,4DAA6E;EAA7E,kEAA6E;EAA7E,mEAA6E;EAA7E,wDAA6E;EAA7E;AAA6E;;AAI7E;EAAA,kBAAqD;EAArD,aAAqD;EAArD,mBAAqD;EAArD,8BAAqD;EAArD;AAAqD;;AAIrD;EAAA,qBAAqB;EAArB;AAAqB;;AAIrB;EAAA,aAA4D;EAA5D,YAA4D;EAA5D,WAA4D;EAA5D,mBAA4D;EAA5D,uBAA4D;EAA5D;AAA4D","sourcesContent":[".buttonGrid {\n  @apply grid gap-4;\n}\n\n.button {\n  @apply relative overflow-hidden rounded-xl transition-all duration-300 hover:scale-[1.02];\n  composes: group from global;\n}\n\n.buttonGradient {\n  @apply absolute inset-0 bg-gradient-to-r from-gray-800 to-gray-700 opacity-95;\n}\n\n.buttonContent {\n  @apply relative p-6 flex items-center justify-between;\n}\n\n.iconContainer {\n  @apply p-3 rounded-lg;\n}\n\n.sendIcon {\n  @apply w-8 h-8 rounded-full flex items-center justify-center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonGrid": `ContactButtons_buttonGrid__LxdpQ`,
	"button": `ContactButtons_button__akzaS group`,
	"buttonGradient": `ContactButtons_buttonGradient__gjr5u`,
	"buttonContent": `ContactButtons_buttonContent__accKX`,
	"iconContainer": `ContactButtons_iconContainer__aI5uo`,
	"sendIcon": `ContactButtons_sendIcon__PfmKG`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactModal_overlay__PTUHA {

    position: fixed;

    inset: 0px;

    z-index: 50;

    display: flex;

    align-items: center;

    justify-content: center;

    background-color: rgb(0 0 0 / 0.5);

    padding: 1rem;

    --tw-backdrop-blur: blur(4px);

    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.ContactModal_modal__K1L6K {

    position: relative;

    width: 100%;

    max-width: 28rem;

    border-radius: 0.75rem;

    border-width: 1px;

    border-color: rgb(55 65 81 / 0.5);

    background-color: rgb(31 41 55 / 0.9);

    padding: 0.75rem;

    --tw-backdrop-blur: blur(12px);

    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

@media (min-width: 1024px) {

    .ContactModal_modal__K1L6K {

        padding: 1rem
    }
}

.ContactModal_closeButton__-YvmC {

    position: absolute;

    top: 0.5rem;

    right: 0.5rem;

    border-radius: 0.5rem;

    padding: 0.75rem;

    --tw-text-opacity: 1;

    color: rgb(156 163 175 / var(--tw-text-opacity));

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.ContactModal_closeButton__-YvmC:hover {

    background-color: rgb(55 65 81 / 0.5);

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.ContactModal_form__6siW0 {

    margin-top: 0.75rem
}

.ContactModal_form__6siW0 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse))
}

.ContactModal_input__MwIiz {

    width: 100%;

    border-radius: 0.5rem;

    background-color: rgb(55 65 81 / 0.5);

    padding: 0.75rem;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.ContactModal_disabled__VKRrT {

    cursor: not-allowed;

    opacity: 0.5
}`, "",{"version":3,"sources":["webpack://./src/styles/contactStyles/ContactModal.module.css"],"names":[],"mappings":"AACE;;IAAA,eAA2F;;IAA3F,UAA2F;;IAA3F,WAA2F;;IAA3F,aAA2F;;IAA3F,mBAA2F;;IAA3F,uBAA2F;;IAA3F,kCAA2F;;IAA3F,aAA2F;;IAA3F,6BAA2F;;IAA3F;AAA2F;;AAI3F;;IAAA,kBAA+G;;IAA/G,WAA+G;;IAA/G,gBAA+G;;IAA/G,sBAA+G;;IAA/G,iBAA+G;;IAA/G,iCAA+G;;IAA/G,qCAA+G;;IAA/G,gBAA+G;;IAA/G,8BAA+G;;IAA/G;AAA+G;;AAA/G;;IAAA;;QAAA;IAA+G;AAAA;;AAI/G;;IAAA,kBAAkH;;IAAlH,WAAkH;;IAAlH,aAAkH;;IAAlH,qBAAkH;;IAAlH,gBAAkH;;IAAlH,oBAAkH;;IAAlH,gDAAkH;;IAAlH,+FAAkH;;IAAlH,wDAAkH;;IAAlH;AAAkH;;AAAlH;;IAAA,qCAAkH;;IAAlH,oBAAkH;;IAAlH;AAAkH;;AAIlH;;IAAA;AAAqB;;AAArB;;IAAA,uBAAqB;;IAArB,+DAAqB;;IAArB;AAAqB;;AAIrB;;IAAA,WAAsD;;IAAtD,qBAAsD;;IAAtD,qCAAsD;;IAAtD,gBAAsD;;IAAtD,oBAAsD;;IAAtD;AAAsD;;AAItD;;IAAA,mBAAoC;;IAApC;AAAoC","sourcesContent":[".overlay {\n  @apply fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4;\n}\n\n.modal {\n  @apply bg-gray-800/90 backdrop-blur-md rounded-xl p-3 lg:p-4 relative max-w-md w-full border border-gray-700/50;\n}\n\n.closeButton {\n  @apply absolute top-2 right-2 p-3 text-gray-400 hover:text-white transition-colors rounded-lg hover:bg-gray-700/50;\n}\n\n.form {\n  @apply space-y-3 mt-3;\n}\n\n.input {\n  @apply w-full bg-gray-700/50 rounded-lg p-3 text-white;\n}\n\n.disabled {\n  @apply opacity-50 cursor-not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `ContactModal_overlay__PTUHA`,
	"modal": `ContactModal_modal__K1L6K`,
	"closeButton": `ContactModal_closeButton__-YvmC`,
	"form": `ContactModal_form__6siW0`,
	"input": `ContactModal_input__MwIiz`,
	"disabled": `ContactModal_disabled__VKRrT`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.GradientBackground_container__CP\\+bf {

    position: absolute;

    inset: 0px;

    overflow: hidden
}

.GradientBackground_gradientBlue__PeJoK {

    position: absolute;

    top: 0px;

    left: 0px;

    height: 24rem;

    width: 24rem
}

@keyframes GradientBackground_pulse__FsAEX {

    50% {

        opacity: .5
    }
}

.GradientBackground_gradientBlue__PeJoK {

    animation: GradientBackground_pulse__FsAEX 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    border-radius: 9999px;

    background-color: rgb(59 130 246 / 0.1);

    --tw-blur: blur(64px);

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.GradientBackground_gradientPurple__OxkDQ {

    position: absolute;

    bottom: 0px;

    right: 0px;

    height: 24rem;

    width: 24rem
}

@keyframes GradientBackground_pulse__FsAEX {

    50% {

        opacity: .5
    }
}

.GradientBackground_gradientPurple__OxkDQ {

    animation: GradientBackground_pulse__FsAEX 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    border-radius: 9999px;

    background-color: rgb(168 85 247 / 0.1);

    --tw-blur: blur(64px);

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);

    transition-delay: 1000ms
}`, "",{"version":3,"sources":["webpack://./src/styles/contactStyles/GradientBackground.module.css"],"names":[],"mappings":";AAEE;;IAAA,kBAAuC;;IAAvC,UAAuC;;IAAvC;AAAuC;;AAIvC;;IAAA,kBAAgG;;IAAhG,QAAgG;;IAAhG,SAAgG;;IAAhG,aAAgG;;IAAhG;AAAgG;;AAAhG;;IAAA;;QAAA;IAAgG;AAAA;;AAAhG;;IAAA,mFAAgG;;IAAhG,qBAAgG;;IAAhG,uCAAgG;;IAAhG,qBAAgG;;IAAhG;AAAgG;;AAIhG;;IAAA,kBAAiH;;IAAjH,WAAiH;;IAAjH,UAAiH;;IAAjH,aAAiH;;IAAjH;AAAiH;;AAAjH;;IAAA;;QAAA;IAAiH;AAAA;;AAAjH;;IAAA,mFAAiH;;IAAjH,qBAAiH;;IAAjH,uCAAiH;;IAAjH,qBAAiH;;IAAjH,iLAAiH;;IAAjH;AAAiH","sourcesContent":["\n.container {\n  @apply absolute inset-0 overflow-hidden;\n}\n\n.gradientBlue {\n  @apply absolute top-0 left-0 w-96 h-96 bg-blue-500/10 rounded-full filter blur-3xl animate-pulse;\n}\n\n.gradientPurple {\n  @apply absolute bottom-0 right-0 w-96 h-96 bg-purple-500/10 rounded-full filter blur-3xl animate-pulse delay-1000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `GradientBackground_container__CP+bf`,
	"gradientBlue": `GradientBackground_gradientBlue__PeJoK`,
	"pulse": `GradientBackground_pulse__FsAEX`,
	"gradientPurple": `GradientBackground_gradientPurple__OxkDQ`
};
export default ___CSS_LOADER_EXPORT___;
